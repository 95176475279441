// JavaScript | Main

/********** GLOBAL ******************************************/

var bPopulatedFromSess = false;
var iYearDiscount = 0;
var aOrderedProducts = getOrderedProducts();
var aOrderComponents = getOrderComponents();
var oWasteTypeRdos, oContainerRdos, oCollectionRdos, oDiscountRdos;
var closedCookie = '';
var closedNotificationCookie;
var i = 0;
var oBoxElement;
var sitename = getSiteName();


$(function () { objectFitImages(); });

window._paq = window._paq || [];

var htmlPrdCart = // Based on Flexbox
    '<div class="orderline" data-prid="{id}">' +
    '<div class="orderline__product">' +
    '<img style="max-height: 50px;" src="{wastetype_img}" alt="{wastetype}" />' +
    '<p class="smaller">{wastetype}</p>' +
    '</div>' +
    '<span class="icon icon-plus"></span>' +
    '<div class="orderline__product">' +
    '<img style="max-height: 50px;" src="{container_img}" alt="{container}" />' +
    '<p class="smaller">{container}</p>' +
    '</div>' +
    '<span class="icon icon-plus"></span>' +
    '<div class="orderline__product">' +
    '<img style="max-height: 50px;" src="{collection_img}" alt="{collection}" />' +
    '<p class="smaller">{collection}</p>' +
    '</div>' +
    '<div class="icon icon-trash"></div>' +
    '</div>';

var htmlYearDiscountOption = '<div class="radio">' +
    '<label for="YearDiscount_{id}">' +
    '<input type="radio" id="YearDiscount_{id}" name="YearDiscountId" value="{id}" data-title="{description}" data-percentage="{percentage}" {selected} />' +
    '<span class="cr"><span class="cr-icon icon-circle"></span></span>' +
    '<small>{description}</small>' +
    '</label>' +
    '</div>';

var adviceCocaColaProductResult = '' +
    '<img src="{container_img}" alt="" class="widget__summary__img">' +
    '<div class="widget__summary__wrapper">' +
    '<div class="widget__summary__content widget__summary__content__column--price">' +
    '<span>Huidig tarief van {currentCollector}</span>' +
    '<span class="js-currentRate">{currentRate}</span>' +
    '</div>' +

    '<div class="widget__summary__content">' +
    '<div class="widget__summary__content__column">' +
    '<span>'+ sitename +' tarief:</span>' +
    '</div>' +

    '<div class="widget__summary__content__column">' +
    '<span>{container} {wasteType} <br />' +
    '<span>{collection}</span></span>' +
    '</div>' +

    '<div class="widget__summary__content__column widget__summary__content__column--price">' +
    '<span class="js-productPrice">{price}</span>' +
    '</div>' +
    '</div>' +

    '<div class="widget__summary__content">' +
    '<div class="widget__summary__content__column">' +
    '<span>KORTING:</span>' +
    '</div>' +

    '<div class="widget__summary__content__column">' +
    '<span>{discountPercentage}% Coca-Cola Club Member</span>' +
    '</div>' +

    '<div class="widget__summary__content__column widget__summary__content__column--price">' +
    '<span class="js-discountAmount">{discountAmount}</span>' +
    '</div>' +
    '</div>' +

    '<div class="widget__summary__content js-yearDiscountsContainer">' +
    '<div class="widget__summary__content__column">' +
    '<span>MEER KORTING:</span>' +
    '</div>' +

    '<div class="widget__summary__content__column pseudo-form">' +
    '<span>{yearDiscounts}</span>' +
    '</div>' +

    '<div class="widget__summary__content__column widget__summary__content__column--price">' +
    '<span class="js-yearDiscount">{yearDiscountAmount}</span>' +
    '</div>' +
    '</div>' +

    '<div class="widget__summary__content js-specialDiscountContainer">' +
    '<div class="widget__summary__content__column">' +
    '<span class="special-discount-text">NOG MEER KORTING:</span>' +
    '</div>' +

    '<div class="widget__summary__content__column">' +
    '<span>' +
    'Eerste 3 maanden gratis<br />' +
    '<span>(alleen bij 1 of 2 jarig overeenkomst)</span>' +
    '</span>' +
    '</div>' +

    '<div class="widget__summary__content__column widget__summary__content__column--price">' +
    '<span class="js-specialDiscount">{specialDiscountAmount}</span>' +
    '</div>' +
    '</div>' +

    '<div class="widget__summary__line"></div>' +

    '<div class="widget__summary__content">' +
    '<div class="widget__summary__content__column">' +
    '<span>&nbsp;</span>' +
    '</div>' +

    '<div class="widget__summary__content__column">' +
    '<span class="widget__summary__content__final">'+ sitename +' tarief</span>' +
    '</div>' +

    '<div class="widget__summary__content__column widget__summary__content__column--price">' +
    '<span class="widget__summary__content__final js-newRate">{newRate}</span>' +
    '</div>' +
    '</div>' +
    '</div>';

var adviceWidgetProductResult = '' +
    '<img src="{container_img}" alt="" class="widget__summary__img">' +
    '<div class="widget__summary__wrapper">' +
    '<div class="widget__summary__content">' +
    '<span>{container} {wasteType} <br><span>{collection}</span></span>' +
    '<span>{price}</span>' +
    '</div>' +

    '<div class="widget__summary__content">' +
    '<span>Huidig tarief</span>' +
    '<span>{currentRate}</span>' +
    ' </div>' +

    '<div class="widget__summary__line"></div>' +

    '<div class="widget__summary__content">' +
    '<span class="widget__summary__content__final">Verschil per maand</span>' +
    '<span class="widget__summary__content__final">{priceDifference}</span>' +
    '</div>' +
    '</div>';

var adviceWidgetProductAdvice = '' +
    '<img src="{container_img}" alt="" class="widget__summary__img">' +
    '<div class="widget__summary__wrapper">' +
    '<div class="widget__summary__content">' +
    '<span>{container} {wasteType} <br><span>{collection}</span></span>' +
    '<span>{price}</span>' +
    '</div>' +
    '</div>';

var iActiveCartPrd = 0;

var oFinalTotalPrice, oPaymentMethodRdos;
var iOrigTotalPrice = 0;

var helpPopup = $('.help-popup');
var oNotificationBar = $('.js-notification');

/********** HELPERS ****************************************/

if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (var i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}

/********** DOMREADY ****************************************/

window.addEventListener('load', function (event) {
    oBoxElement = document.querySelector("form");
    if (oBoxElement) {
        createObserver();
    }
}, false);

$(function () {
    hideCodecheckField();

    if ($('.js-advicewidget').length > 0) { toggleAdviceWidget(); }
    if ($('#rentContainer').length > 0) { initRentContainerStep1(); }
    if ($('#rentContainerFinish').length > 0) { initRentContainerStep2(); }
    if ($('.overlay-product').length > 0) { openOverlay(); }
    if ($('.overlay').length > 0) { openMenuOverlay(); }
    if ($('.tab-list').length > 0) { tabList(); }
    if ($('.login-panel').length > 0) { prefillLogin(); }
    if ($('.js-block-prices').length > 0) { initPricesBlock(); }

    if ($('.block-wastetypes').length > 0) { initWasteTypesBlock(); }

    if ($('#accountPaymentData').length > 0) {
        $('#PaymentmethodId').on('change', function (e) {
            toggleEditPaymentMethodFields(e);
        });
    }

    if ($('.js-currencyInput').length > 0) {
        $('.js-currencyInput').on('keydown', restrictCurrencyInput);
    }

    closedCookie = Cookies.get('popupClosed');
    helpPopupTimeout(closedCookie);

    if (oNotificationBar.length) {
        closedNotificationCookie = Cookies.get('notificationClosed');
        showNotification(closedNotificationCookie);
    }

    //if ($("#rentContainerFinish").length) { //GPGROOT-498 dit zorgt er voor dat de validatie van het contact formulier stuk gaat. dus alleen doen op het container bestellen formulier
    //    $("#contactSubmit input").on("invalid", this.setCustomValidity('error'));
    //    $("#contactSubmit input").on("input", this.setCustomValidity('error'));
    //}

    $('[data-toggle="popover"]').popover();

    if ($(".js-get-customer").length) {
        $(".js-get-customer").on("blur", function (e) {
            //try to get customer by email adres
            $.get("/umbraco/surface/RentContainerFinish/GetCustomerByEmail", { email: $(this).val() })
                .done(function (data) {
                    $(".discount-desc").find("p:nth-child(2)").html(data.YearDiscountDescription);
                    $(".discount-desc").parent().next().html(data.DiscountHtml);
                    $(".totalprice").data("price", data.TotalPriceCents).html(data.TotalPriceFormat);
                    $('#changeYearDiscount .modal-title').html(data.ModalTitle);
                    $('#changeYearDiscount .modal-body p').html(data.ModalBody);
                    $('#changeYearDiscount').modal();
                });
        });
    }

    $('.js-form').validator({
        disable: false
    }).on('submit', function (e) {
        if (!e.isDefaultPrevented()) {
            // Form is valid, still need to stop the submit to exectute ReCaptcha
            // Form is submitted in recaptchaValidated()
            e.preventDefault();
            grecaptcha.reset();
            grecaptcha.execute();
        } else {
            // Form is invalid
            return false;
        }
    });
});

function createObserver() {
    var observer;

    var options = {
        root: null,
        rootMargin: "0px",
        threshold: buildThresholdList()
    };

    //var recaptcha = $(".grecaptcha-badge");

    observer = new IntersectionObserver(handleIntersect, options);
    observer.observe(oBoxElement);
}

function buildThresholdList() {
    var thresholds = [];
    var numSteps = 20;

    for (var i = 1.0; i <= numSteps; i++) {
        var ratio = i / numSteps;
        thresholds.push(ratio);
    }

    thresholds.push(0);
    return thresholds;
}

function handleIntersect(entries, observer) {
    entries.forEach(function (entry) {
        if (entry.intersectionRatio > 0) {
            $(".grecaptcha-badge").show();
        }
        else {
            $(".grecaptcha-badge").hide();
        }
    });
}

var recaptchaValidated = function (token) {
    var aResponseFields = document.querySelectorAll('.g-recaptcha-response');
    var validated = false;
    var oValidatedForm;

    if (aResponseFields.length > 0) {
        for (var i = 0; i < aResponseFields.length; i += 1) {
            if (aResponseFields[i].value === token) {
                validated = true;
                oValidatedForm = aResponseFields[i].closest('form');
                break;
            }
        }

        if (validated && oValidatedForm !== null) {
            oValidatedForm.submit();
        }
    }
};


$(".step1-1 .order-step-item").on("click", function () {
    $('html, body').stop().animate({
        scrollTop: $(".step1-2").offset().top
    }, 1200);
});
$(".step1-2 .order-step-item").on("click", function () {
    $('html, body').stop().animate({
        scrollTop: $(".step1-3").offset().top
    }, 1200);
});

$('.js-collection-days-dropdown').change(function () {
    var subscriptionId = $(this).val();

    $.ajax({
        url: '/umbraco/surface/AccountCollectionDays/GetAccountCollectionDays/' + subscriptionId,
        type: 'get',
        datatype: 'html',
        beforeSend: function () {
            $('.js-collection-days-reload').hide();
            $('.js-collection-days-spinner').show();

            var oCollectionCalendarLink = $('.block-subscription__link--calendar');
            if (oCollectionCalendarLink.length > 0) {
                var sCurrLinkCollectionCalendar = oCollectionCalendarLink.attr('href');
                var regex = /subscriptionId=[0-9]+$/gi;
                sCurrLinkCollectionCalendar = sCurrLinkCollectionCalendar.replace(regex, 'subscriptionId=' + subscriptionId);

                oCollectionCalendarLink.attr('href', sCurrLinkCollectionCalendar);
            }
        },
        complete: function () {
            $('.js-collection-days-reload').show();
            $('.js-collection-days-spinner').hide();
        },
        success: function (response) {
            if (response) {
                $('.js-collection-days-reload').html(response);
            } else {
                $('.js-collection-days-reload').html('<p>Error</p>');
            }
        },
        error: function (xhr) {
            $('.js-collection-days-reload').html('<p>Error</p>');
        }
    });

});

/********** FUNCTIONS ***************************************/

function toggleAdviceWidget() {
    var aProducts = jsonProducts;
    var oAdviceWidget = document.querySelector('.js-advicewidget');
    var bIsCocaCola = oAdviceWidget.classList.contains('js-cocacola');
    var oWidgetBranding = document.querySelector('.widget__branding');

    var aButtons = document.querySelectorAll('[data-button-id]');
    var aSubmitButtons = document.querySelectorAll('[data-submit-id]');
    var currentPage = document.querySelector('[data-page-id]');
    var resetContainer = document.querySelector('[data-page-id="reset"]');

    var oWasteTypeSelect = document.querySelector('select[name="wasteType"]');
    var oContainerSizeSelect = document.querySelector('select[name="containerSize"]');
    var oCollectionTypeSelect = document.querySelector('select[name="collectionType"]');
    var oWidgetOrderContainerBtn = document.querySelectorAll('.js-result-order-prefill');

    var oFormFieldsInput = oAdviceWidget.querySelectorAll('input[type="text"], input[type="number"], input[type="email"]');

    var selectedWasteType = null;
    var selectedContainerType = null;
    var selectedCollectionType = null;
    var selectedWidgetProduct = null;

    var bShowWidget = false;

    // Deeplink
    if (!bIsCocaCola) {
        if (window.location.href.indexOf('?widget=') !== -1) {
            var widgetMode = window.location.href.split('?widget=')[1];
            if (widgetMode === 'compare-prices' || widgetMode === 'choose-container') {
                changePage(widgetMode, false);
            }
        } else {
            bShowWidget = true;
        }
    } else {
        bShowWidget = true;
    }

    if (bShowWidget) {
        oAdviceWidget.classList.add('is-loaded');
    }

    oWasteTypeSelect.addEventListener('change', setAvailableContainerTypes);
    oContainerSizeSelect.addEventListener('change', setAvailableCollectionTypes);
    oCollectionTypeSelect.addEventListener('change', getWidgetProduct);

    oFormFieldsInput.forEach(function (field) {
        field.addEventListener('blur', function () {
            doValidateFormField(field.parentElement, field);
        });
    });

    for (var i = 0; i < oWidgetOrderContainerBtn.length; i += 1) {
        oWidgetOrderContainerBtn[i].addEventListener('click', widgetPrefillOrder);
    }

    var aRadioButtons = document.querySelectorAll('[data-radio-id]');
    var currentInput = document.querySelector('[data-input-id]');

    var widgetDiv = document.querySelector('.js-advicewidget-container');

    var mobileBreakpoint = 991;
    var iScreenWidth = window.innerWidth;

    var targetPage;
    var targetInput;

    setAvailableContainerTypes(null, oWasteTypeSelect.value);

    window.addEventListener("resize", function () {
        iScreenWidth = window.innerWidth;
    });

    function setAvailableContainerTypes(e, value) {
        selectedWasteType = e !== null ? e.target.value : value;
        oContainerSizeSelect.selectedIndex = null;
        oCollectionTypeSelect.disabled = true;

        var availableContainers = aProducts.filter(function (product) {
            if (product.WasteTypeId === parseInt(selectedWasteType, 10)) {
                return product.ContainerId;
            }
        });

        var availableContainerIds = availableContainers.map(function (container) {
            return container.ContainerId;
        });

        availableContainerIds = availableContainerIds.filter(function (containerId, i) {
            return availableContainerIds.indexOf(containerId) === i;
        });

        for (var i = 0; i < oContainerSizeSelect.options.length; i += 1) {
            if (availableContainerIds.indexOf(parseInt(oContainerSizeSelect.options[i].value, 10)) === -1) {
                oContainerSizeSelect.options[i].style.display = 'none';
            } else {
                oContainerSizeSelect.options[i].style.display = null;
            }
        }
    }

    function setAvailableCollectionTypes(e, value) {
        selectedContainerType = e !== null ? e.target.value : value;
        oCollectionTypeSelect.selectedIndex = null;
        oCollectionTypeSelect.disabled = false;

        doValidateFormField(e.target.parentElement, e.target);

        var availableCollectionType = aProducts.filter(function (product) {
            if (product.WasteTypeId === parseInt(selectedWasteType, 10) && product.ContainerId === parseInt(selectedContainerType, 10)) {
                return product.CollectionId;
            }
        });

        var availableCollectionTypeIds = availableCollectionType.map(function (collection) {
            return collection.CollectionId;
        });

        availableCollectionTypeIds = availableCollectionTypeIds.filter(function (collectionid, i) {
            return availableCollectionTypeIds.indexOf(collectionid) === i;
        });

        for (var i = 0; i < oCollectionTypeSelect.options.length; i += 1) {
            if (availableCollectionTypeIds.indexOf(parseInt(oCollectionTypeSelect.options[i].value, 10)) === -1) {
                oCollectionTypeSelect.options[i].style.display = 'none';
            } else {
                oCollectionTypeSelect.options[i].style.display = null;
            }
        }
    }

    function getWidgetProduct(e, value) {
        selectedCollectionType = e !== null ? e.target.value : value;

        doValidateFormField(e.target.parentElement, e.target);

        selectedWidgetProduct = aProducts.filter(function (product) {
            if (product.WasteTypeId === parseInt(selectedWasteType, 10) &&
                product.ContainerId === parseInt(selectedContainerType, 10) &&
                product.CollectionId === parseInt(selectedCollectionType, 10)) {
                return product;
            }
        });
    }

    function changePage(oButton, bAnimate) {
        targetPage = document.querySelector("[data-page-id=" + oButton + "]");
        var currentWidget;

        if (targetPage === currentPage) { return; }

        switch (targetPage.getAttribute('data-page-id')) {
            case 'start-page':
                resetAdviceWidget();
                transitionPage(bAnimate);
                break;

            case 'result-prices':
                var paymentAmount = document.querySelector('input[name="paymentAmount"]');

                if (document.forms['price-form'].checkValidity() &&
                    document.forms['price-form']['collectionType'].value !== "" &&
                    document.forms['price-form']['containerSize'].value !== "") {

                    transitionPage(bAnimate);

                    currentWidget = $('button[data-submit-id="' + oButton + '"]').closest(".widget");
                    var currentSupplier = currentWidget.find('[name="currentSupplier"]').val();

                    $.ajax({
                        type: "POST",
                        url: "/umbraco/api/SendEmail/SendAdviesTarieven/",
                        data: {
                            docType: docTypeAlias,
                            productName: selectedWidgetProduct[0].WasteType + " - " + selectedWidgetProduct[0].Container + " - " + selectedWidgetProduct[0].Collection,
                            currentSupplier: currentSupplier,
                            paymentAmount: currentWidget.find('[name="paymentAmount"]').val(),
                            mkbAmount: selectedWidgetProduct[0].Price,
                            email: currentWidget.find('[name="email"]').val()
                        }
                    });

                    showWidgetResult(selectedWidgetProduct, paymentAmount.valueAsNumber, targetPage, currentSupplier);
                } else {
                    document.forms['price-form'].querySelectorAll('input[type="text"], input[type="number"], input[type="email"], select, input[type="radio"], input[type="checkbox"]').forEach(function (field) { doValidateFormField(field.parentElement, field); });

                    showWidgetErrorMessage(currentPage);
                    return false;
                }
                break;

            case 'result-container':
                if (document.querySelector('input[data-radio-id="garbageBags"]').checked) {
                    var garbageBagsAmount = parseInt(document.forms['form-advice']['amountTrash'].value, 10);
                    var minGarbageBagsAmount = parseInt(document.forms['form-advice']['amountTrash'].min, 10);
                    var maxGarbageBagsAmount = parseInt(document.forms['form-advice']['amountTrash'].max, 10);
                    var productIdtoUse;

                    switch (true) {
                        case garbageBagsAmount < minGarbageBagsAmount || garbageBagsAmount > maxGarbageBagsAmount:
                            showWidgetErrorMessage(currentPage);
                            return false;

                        case garbageBagsAmount === 1:
                            productIdtoUse = 2;
                            break;

                        case garbageBagsAmount === 2:
                            productIdtoUse = 12;
                            break;

                        case garbageBagsAmount > 2 && garbageBagsAmount <= 4:
                            productIdtoUse = 17;
                            break;

                        case garbageBagsAmount > 4 && garbageBagsAmount <= 8:
                            productIdtoUse = 16;
                            break;

                        case garbageBagsAmount > 8 && garbageBagsAmount <= 10:
                            productIdtoUse = 14;
                            break;

                        case garbageBagsAmount > 10:
                            productIdtoUse = 19;
                            break;
                    }

                    selectedWidgetProduct = aProducts.filter(function (product) {
                        if (product.Id === productIdtoUse) {
                            return product;
                        }
                    });

                    if (document.forms['form-advice'].checkValidity()) {
                        transitionPage(bAnimate);

                        currentWidget = $('button[data-submit-id="' + oButton + '"]').closest(".widget");

                        $.ajax({
                            type: "POST",
                            url: "/umbraco/api/SendEmail/SendAdviesContainer/",
                            data: {
                                containerType: selectedWidgetProduct[0].WasteType + " - " + selectedWidgetProduct[0].Container + " - " + selectedWidgetProduct[0].Collection,
                                situation: '-',
                                amountTrash: currentWidget.find('[name="amountTrash"]').val(),
                                email: currentWidget.find('[name="email"]').val()
                            }
                        });

                        showWidgetResult(selectedWidgetProduct, null, targetPage);
                    } else {
                        document.forms['form-advice'].querySelectorAll('input[type="text"], input[type="number"], input[type="email"], select, input[type="radio"], input[type="checkbox"]').forEach(function (field) { doValidateFormField(field.parentElement, field); });

                        showWidgetErrorMessage(currentPage);
                    }
                } else {
                    if (document.forms['form-advice']['email'].value !== '') {
                        var selectedOption = document.forms['form-advice']['situation'];
                        selectedWidgetProduct = aProducts.filter(function (product) {
                            if (product.Id === parseInt(selectedOption.value, 10)) {
                                return product;
                            }
                        });

                        transitionPage(bAnimate);

                        currentWidget = $('button[data-submit-id="' + oButton + '"]').closest(".widget");

                        $.ajax({
                            type: "POST",
                            url: "/umbraco/api/SendEmail/SendAdviesContainer/",
                            data: {
                                containerType: selectedWidgetProduct[0].WasteType + " - " + selectedWidgetProduct[0].Container + " - " + selectedWidgetProduct[0].Collection,
                                situation: currentWidget.find('[name="situation"] option[value="' + currentWidget.find('[name="situation"]').val() + '"]').html(),
                                amountTrash: null,
                                email: currentWidget.find('[name="email"]').val()
                            }
                        });

                        showWidgetResult(selectedWidgetProduct, null, targetPage);
                    } else {
                        document.forms['form-advice'].querySelectorAll('input[type="text"], input[type="number"], input[type="email"], select, input[type="radio"], input[type="checkbox"]').forEach(function (field) { doValidateFormField(field.parentElement, field); });

                        showWidgetErrorMessage(currentPage);
                    }
                }
                break;

            default:
                transitionPage(bAnimate);
        }

        function transitionPage(bAnimate) {
            if (bIsCocaCola && targetPage.getAttribute('data-page-id') === 'compare-prices') {
                resetAdviceWidget();
            }

            if (!oAdviceWidget.classList.contains('is-loaded')) {
                oAdviceWidget.classList.add('is-loaded');
            }

            var iTiming = bAnimate ? 0.5 : 0;
            var pageTransition = gsap.timeline();
            hideWidgetErrorMsg(currentPage);

            if (iTiming > 0) {
                pageTransition.to(resetContainer, {
                    duration: iTiming,
                    y: "-50px",
                    autoAlpha: 0
                })
                .set(resetContainer, {
                    display: "none"
                });

                pageTransition.to(currentPage, {
                    duration: iTiming,
                    y: "-50px",
                    autoAlpha: 0
                })
                .set(currentPage, {
                    display: "none"
                })
                .set(targetPage, {
                    display: "block",
                    y: "50px",
                    autoAlpha: 0
                })
                .to(targetPage, {
                    duration: iTiming,
                    y: "0px",
                    autoAlpha: 1
                });
            } else {
                pageTransition.set(resetContainer, {
                    y: "-50px",
                    autoAlpha: 0
                })
                .set(resetContainer, {
                    display: "none"
                });

                pageTransition.set(currentPage, {
                    y: "-50px",
                    autoAlpha: 0
                })
                .set(currentPage, {
                    display: "none"
                })
                .set(targetPage, {
                    display: "block",
                    y: "50px",
                    autoAlpha: 0
                })
                .set(targetPage, {
                    y: "0px",
                    autoAlpha: 1
                });
            }



            // Only display the reset button if the user isn't on the start page
            if ((!bIsCocaCola && targetPage.getAttribute('data-page-id') !== 'start-page') || (bIsCocaCola && targetPage.getAttribute('data-page-id') !== 'compare-prices')) {
                pageTransition.set(resetContainer, {
                    display: "block",
                    y: "50px",
                    autoAlpha: 0
                }).to(resetContainer, {
                    duration: iTiming,
                    y: "0",
                    autoAlpha: 1
                });
            }

            if (iScreenWidth > mobileBreakpoint) {
                widgetDiv.scrollIntoView({ behavior: "smooth", block: "start" });
            }

            currentPage = targetPage;
        }
    }

    function showWidgetErrorMessage(panel) {
        var errorMsg = panel.querySelector('.js-error-msg');
        if (errorMsg) {
            errorMsg.style.display = 'block';
        }
    }

    function hideWidgetErrorMsg(panel) {
        var errorMsg = panel.querySelector('.js-error-msg');
        if (errorMsg) {
            errorMsg.style.display = null;
        }
    }

    function resetAdviceWidget() {
        var widgetPages = document.querySelectorAll('[data-page-id]');
        selectedWidgetProduct = null;

        if (oWidgetBranding !== null) {
            oWidgetBranding.classList.remove('result');
        }

        for (var page = 0; page < widgetPages.length; page++) {
            var pageInputs = widgetPages[page].querySelectorAll('input:not([type="radio"]):not([type="checkbox"])');
            var pageSelects = widgetPages[page].querySelectorAll('select');
            var pageRadios = widgetPages[page].querySelectorAll('input[type="radio"]');
            var pageCheckboxes = widgetPages[page].querySelectorAll('input[type="checkbox"]');
            var errorMsg = widgetPages[page].querySelector('.js-error-msg');

            if (errorMsg) {
                errorMsg.style.display = null;
            }

            document.querySelectorAll('.widget__form__validate--invalid').forEach(function (elem) {
                elem.removeAttribute('style');
                elem.previousElementSibling.removeAttribute('style');
            });

            for (var input = 0; input < pageInputs.length; input++) { pageInputs[input].value = ''; }
            for (var select = 0; select < pageSelects.length; select++) { pageSelects[select].querySelector('option').selected = 'selected'; }
            for (var checkbox = 0; checkbox < pageCheckboxes.length; checkbox++) { pageCheckboxes[checkbox].checked = false; }

            var radioNames = [];
            var radio;

            for (radio = 0; radio < pageRadios.length; radio++) {
                radioNames.push(pageRadios[radio].getAttribute('name'));
                pageRadios[radio].checked = false;
            }

            for (radio = 0; radio < radioNames.length; radio++) {
                var firstOption = document.querySelector('input[name="' + radioNames[radio] + '"]');

                firstOption.checked = true;
                firstOption.click();
            }
        }

        oWasteTypeSelect.dispatchEvent(new Event('change'));
    }

    function showWidgetResult(selectedProduct, monthPrice, panel, supplierName) {
        if (selectedProduct !== null) {
            if (typeof supplierName === 'undefined') {
                supplierName = '';
            }

            if (bIsCocaCola) {
                if (oWidgetBranding !== null) {
                    oWidgetBranding.classList.add('result');
                }
            }

            var monthPriceCents = monthPrice !== null ? monthPrice * 100 : "";
            var oCheaperDivs = panel.querySelectorAll('.js-result--cheaper');
            var oFlexibleDivs = panel.querySelectorAll('.js-result--flexible');
            var widgetSummary = panel.querySelector('.js-widget-summary');
            var oProd = selectedProduct[0];
            var currAdviceWidgetProduct;

            if (monthPrice !== null) {
                currAdviceWidgetProduct = !bIsCocaCola ? adviceWidgetProductResult : adviceCocaColaProductResult;
            } else {
                currAdviceWidgetProduct = adviceWidgetProductAdvice;
            }

            var productPrice = (oProd.Price / 100);
            var productPriceAfterDiscounts = productPrice;
            var specialDiscount = 0;
            var absoluteYearDiscount = 0;

            currAdviceWidgetProduct = currAdviceWidgetProduct.replace('{container}', oProd.Container);
            currAdviceWidgetProduct = currAdviceWidgetProduct.replace('{container_img}', '/images/containers/' + oProd.ContainerImg);
            currAdviceWidgetProduct = currAdviceWidgetProduct.replace('{collection}', oProd.Collection);
            currAdviceWidgetProduct = currAdviceWidgetProduct.replace('{wasteType}', oProd.WasteType);
            currAdviceWidgetProduct = currAdviceWidgetProduct.replace('{price}', formatCurrency(productPrice));

            if (bIsCocaCola) {
                currAdviceWidgetProduct = currAdviceWidgetProduct.replace('{currentCollector}', supplierName);

                var cocaColaDiscount = ((productPrice * (discountPercentage / 100)) * -1);

                currAdviceWidgetProduct = currAdviceWidgetProduct.replace('{discountPercentage}', discountPercentage);
                currAdviceWidgetProduct = currAdviceWidgetProduct.replace('{discountAmount}', formatCurrency(cocaColaDiscount));

                var htmlYearDiscounts = '';
                var selectedYearDiscountPercentage = 0;

                for (var iDiscount = 0; iDiscount < jsonYearDiscounts.length; iDiscount++) {
                    var yearDiscount = jsonYearDiscounts[iDiscount];
                    var htmlYearDiscount = htmlYearDiscountOption;

                    htmlYearDiscount = htmlYearDiscount.replace(/{id}/g, yearDiscount.Id);
                    htmlYearDiscount = htmlYearDiscount.replace(/{percentage}/g, yearDiscount.Percentage);
                    htmlYearDiscount = htmlYearDiscount.replace(/{description}/g, yearDiscount.Description);

                    // The middle year discount (currently 5%) should default to selected
                    if (yearDiscount.Id === 4) {
                        htmlYearDiscount = htmlYearDiscount.replace('{selected}', 'checked="checked"');
                        selectedYearDiscountPercentage = yearDiscount.Percentage;
                    } else {
                        htmlYearDiscount = htmlYearDiscount.replace('{selected}', '');
                    }

                    htmlYearDiscounts += htmlYearDiscount;
                }

                currAdviceWidgetProduct = currAdviceWidgetProduct.replace('{yearDiscounts}', htmlYearDiscounts);

                absoluteYearDiscount = (selectedYearDiscountPercentage > 0 ? ((productPrice * (selectedYearDiscountPercentage / 100)) * -1) : 0);
                currAdviceWidgetProduct = currAdviceWidgetProduct.replace('{yearDiscountAmount}', formatCurrency(absoluteYearDiscount));

                specialDiscount = (selectedYearDiscountPercentage > 0 ? (productPrice * 3) : 0);
                currAdviceWidgetProduct = currAdviceWidgetProduct.replace('{specialDiscountAmount}', formatCurrency(specialDiscount));

                // Add the discounts as they are negative numbers
                productPriceAfterDiscounts = (productPrice + cocaColaDiscount + absoluteYearDiscount);

                currAdviceWidgetProduct = currAdviceWidgetProduct.replace('{newRate}', formatCurrency(productPriceAfterDiscounts));
            }

            // Annual savings
            if (monthPrice !== null) {
                var oPriceYearContainers = panel.querySelectorAll('.js-result-price-year');

                var priceDifference = '';
                if (monthPrice !== null) {
                    if (!bIsCocaCola) {
                        priceDifference = round(((monthPriceCents - round(oProd.Price)) / 100), 2);
                    } else {
                        priceDifference = round((monthPriceCents - (productPriceAfterDiscounts * 100)) / 100, 2);
                    }
                }

                var priceDifferenceFormat = monthPrice !== null ? formatCurrency(priceDifference) : "";

                currAdviceWidgetProduct = currAdviceWidgetProduct.replace('{currentRate}', formatCurrency(monthPrice));
                currAdviceWidgetProduct = currAdviceWidgetProduct.replace('{priceDifference}', priceDifferenceFormat);

                var priceDifferenceAnnual = (priceDifference * 12);
                var priceDifferenceFirstYear = priceDifferenceAnnual;

                if (bIsCocaCola) {
                    var oSingleYearSavings = document.querySelector('.widget__result-text.singleYear');
                    var oMultipleYearSavings = document.querySelector('.widget__result-text.multipleYears');

                    // Reset display of annual savings
                    oSingleYearSavings.classList.remove('hidden');
                    oMultipleYearSavings.classList.add('hidden');

                    if (selectedYearDiscountPercentage >= 5) {
                        priceDifferenceFirstYear += specialDiscount;

                        if (selectedYearDiscountPercentage > 5) {
                            oSingleYearSavings.classList.add('hidden');

                            document.querySelector('.js-result-price-firstYear').textContent = formatCurrency(priceDifferenceFirstYear);
                            oMultipleYearSavings.classList.remove('hidden');
                        } else {
                            priceDifferenceAnnual = priceDifferenceFirstYear;
                        }
                    }
                }

                oPriceYearContainers.forEach(function (oPriceYearContainer) {
                    oPriceYearContainer.textContent = formatCurrency(priceDifferenceAnnual);
                });
            }

            var bMKBcheaper = false;
            if (!bIsCocaCola) {
                bMKBcheaper = (oProd.Price <= monthPriceCents);
            } else {
                var productPriceAfterCocaColaDiscount = (productPrice + cocaColaDiscount);
                bMKBcheaper = ((productPriceAfterCocaColaDiscount * 100) <= monthPriceCents);
            }

            if (!bMKBcheaper) {
                toggleFlexibleDivs(oCheaperDivs, oFlexibleDivs);
            } else {
                toggleCheaperDivs(oCheaperDivs, oFlexibleDivs);
            }

            widgetSummary.innerHTML = currAdviceWidgetProduct;

            if (bIsCocaCola) {
                var oYearDiscountsContainer = document.querySelector('.js-yearDiscountsContainer');
                var oSpecialDiscountContainer = document.querySelector('.js-specialDiscountContainer');

                if (bMKBcheaper) {
                    oYearDiscountsContainer.classList.remove('hidden');
                    oSpecialDiscountContainer.classList.remove('hidden');

                    var oDiscountRdos = document.querySelectorAll('input[name="YearDiscountId"]');
                    if (oDiscountRdos.length > 0) {
                        oDiscountRdos.forEach(function (oRdo) {
                            oRdo.addEventListener('change', widgetRecalcPrices);
                        });
                    }
                } else {
                    oYearDiscountsContainer.classList.add('hidden');
                    oSpecialDiscountContainer.classList.add('hidden');
                }
            }
        }
    }

    function widgetRecalcPrices(e) {
        var oPriceYearContainers = document.querySelectorAll('.js-cocacola .js-result-price-year');
        var oSpecialDiscountContainer = document.querySelector('.js-specialDiscountContainer');

        var oCurrDiscount = e.target;
        var iSelectedYearDiscountPercentage = parseFloat(oCurrDiscount.getAttribute('data-percentage'));

        var oCurrentRate = document.querySelector('.js-currentRate');
        var iCurrentRate = parseFloat(oCurrentRate.textContent.replace(',', '.'));
        var oProductPrice = document.querySelector('.js-productPrice');
        var iProductPrice = parseFloat(oProductPrice.textContent.replace(',', '.'));
        var oCocaColaDiscount = document.querySelector('.js-discountAmount');
        var iCocaColaDiscount = parseFloat(oCocaColaDiscount.textContent.replace(',', '.'));
        var oYearDiscountAmount = document.querySelector('.js-yearDiscount');
        var oSpecialDiscount = document.querySelector('.js-specialDiscount');
        var iSpecialDiscount = parseFloat(oSpecialDiscount.textContent.replace(',', '.'));
        var oNewRate = document.querySelector('.js-newRate');

        var iAbsoluteYearDiscount = (iSelectedYearDiscountPercentage > 0 ? ((iProductPrice * (iSelectedYearDiscountPercentage / 100)) * -1) : 0);
        var iRecalculatedNewRate = (iProductPrice + iCocaColaDiscount + iAbsoluteYearDiscount);

        var iPriceDifference = (iCurrentRate - iRecalculatedNewRate);
        var iPriceDifferenceAnnual = (iPriceDifference * 12);
        var iPriceDifferenceFirstYear = iPriceDifferenceAnnual;

        if (iSelectedYearDiscountPercentage >= 5) {
            oSpecialDiscountContainer.classList.remove('hidden');

            iPriceDifferenceFirstYear += iSpecialDiscount;

            if (iSelectedYearDiscountPercentage > 5) {
                document.querySelector('.widget__result-text.singleYear').classList.add('hidden');

                document.querySelector('.js-result-price-firstYear').textContent = formatCurrency(iPriceDifferenceFirstYear);
                document.querySelector('.widget__result-text.multipleYears').classList.remove('hidden');
            } else {
                document.querySelector('.widget__result-text.singleYear').classList.remove('hidden');
                document.querySelector('.widget__result-text.multipleYears').classList.add('hidden');

                iPriceDifferenceAnnual = iPriceDifferenceFirstYear;
            }
        } else {
            oSpecialDiscountContainer.classList.add('hidden');
            document.querySelector('.widget__result-text.singleYear').classList.remove('hidden');
            document.querySelector('.widget__result-text.multipleYears').classList.add('hidden');
        }

        oYearDiscountAmount.textContent = iAbsoluteYearDiscount;
        oNewRate.textContent = formatCurrency(iRecalculatedNewRate);

        oPriceYearContainers.forEach(function (oPriceYearContainer) {
            oPriceYearContainer.textContent = formatCurrency(iPriceDifferenceAnnual);
        });

        console.log(iAbsoluteYearDiscount, iRecalculatedNewRate);
    }

    function widgetPrefillOrder() {
        var discountid = $("input[name=YearDiscountId]:checked").val();
        window.location.href = '/umbraco/surface/rentcontainer/prefillproduct?productId=' + selectedWidgetProduct[0].Id + '&page=' + docTypeAlias + '&discountid=' + discountid;
    }

    function toggleCheaperDivs(oCheaperDivs, oFlexibleDivs) {
        oCheaperDivs.forEach(function (div) {
            div.classList.remove('hidden');
            //div.style.display = 'flex';
        });

        oFlexibleDivs.forEach(function (div) {
            div.classList.add('hidden');
            //div.style.display = 'none';
        });
    }

    function toggleFlexibleDivs(oCheaperDivs, oFlexibleDivs) {
        oCheaperDivs.forEach(function (div) {
            div.classList.add('hidden');
            //div.style.display = 'none';
        });

        oFlexibleDivs.forEach(function (div) {
            div.classList.remove('hidden');
            //div.style.display = 'flex';
        });
    }

    function toggleInput(radioButton, selector) {
        targetInput = document.querySelector("[data-input-id=" + selector + "]");

        if (radioButton.checked) {
            currentInput.classList.remove("active");
            targetInput.classList.add("active");
        }

        currentInput = targetInput;
        targetInput = null;
    }

    function doValidateFormField(container, field) {
        container.querySelectorAll('.widget__form__validate').forEach(function (elem) { elem.style.display = 'none'; });

        var fieldVal = '';
        switch (field.tagName.toLowerCase()) {
            case 'select':
            case 'textarea':
                fieldVal = field.value;
                break;

            default:
                switch (field.getAttribute('type')) {
                    case 'radio':
                    case 'checkbox':
                        fieldVal = document.querySelector('input[name="' + field.getAttribute('name') + '"]:checked').value;
                        break;

                    case 'email':
                        if (validateEmail(field.value)) {
                            fieldVal = field.value;
                        }
                        break;

                    default:
                        fieldVal = field.value;
                        break;
                }
                break;
        }

        if (fieldVal !== '') {
            var oValidFeedback = container.querySelector('.widget__form__validate--valid');
            if (oValidFeedback) {
                oValidFeedback.style.display = 'inline';
            }
        } else {
            var oInvalidFeedback = container.querySelector('.widget__form__validate--invalid');
            if (oInvalidFeedback) {
                oInvalidFeedback.style.display = 'inline';
            }
        }
    }


    for (var i = 0; i < aButtons.length; i++) {
        aButtons[i].addEventListener("click", function () {
            var buttonSelector = this.dataset.buttonId;
            changePage(buttonSelector, true);
        });
    }

    for (var x = 0; x < aSubmitButtons.length; x++) {
        aSubmitButtons[x].addEventListener("click", function (e) {
            e.preventDefault();
            var buttonSelector = this.dataset.submitId;
            changePage(buttonSelector, true);
            return false;
        });
    }


    for (var r = 0; r < aRadioButtons.length; r++) {
        aRadioButtons[r].addEventListener("click", function () {
            var inputSelector = this.dataset.radioId;
            toggleInput(this, inputSelector);
        });
    }
}

function hideCodecheckField() {
    var oCodeCheck = $('#codeCheckerHolder');

    if (oCodeCheck.length > 0) {
        oCodeCheck.addClass('hiddenContainer');
    }
}

function initRentContainerStep1() {
    var oRentContainerForm = $('#rentContainer');
    var oSubmitBtn = $('#orderComplete');
    oWasteTypeRdos = $('input[name="WasteTypeId"]');
    oContainerRdos = $('input[name="ContainerId"]');
    oCollectionRdos = $('input[name="CollectionId"]');
    oDiscountRdos = $('input[name="YearDiscountId"]');

    oWasteTypeRdos.on('change', step1WasteTypeChanged);
    oContainerRdos.on('change', step1ContainerChanged);
    oCollectionRdos.on('change', step1CollectionChanged);
    oDiscountRdos.on('change', step1DiscountChanged);

    /**
     * GPGROOT-1823
     * Remove RentContainer button loading state on pagehide event
     * Prevents button being disabled after using browser back button in Safari due to bfcache
     */
    window.addEventListener('pagehide', function() {
        removeButtonLoadingState(oSubmitBtn)
    });

    if (aOrderedProducts.length > 0) {
        activateCart();

        var oYearDiscountSess = oDiscountRdos.filter('#YearDiscount_' + iYearDiscount);
        if (oYearDiscountSess.length > 0) {
            oYearDiscountSess.prop('checked', true);
            oYearDiscountSess.trigger('change');
        }

        for (var iPrd = 0; iPrd < aOrderedProducts.length; iPrd++) {
            if (iPrd === aOrderedProducts.length - 1 && !hasPreselect) {
                var latestProd = aOrderedProducts[iPrd];
                // Select latest product in cart and prefill product configuration so it can be edited.

                console.log(oWasteTypeRdos.filter('#WasteType_' + latestProd.WasteTypeId))

                oWasteTypeRdos.filter('#WasteType_' + latestProd.WasteTypeId).prop('checked', true).trigger('change')
                oContainerRdos.filter('#Container_' + latestProd.ContainerId).prop('checked', true).trigger('change');
                oCollectionRdos.filter('#Collection_' + latestProd.CollectionId).prop('checked', true);

                iActiveCartPrd = latestProd.Id;
            } else {
                addProductToCart(aOrderedProducts[iPrd]);
            }
        }
    }

    var checkedContainers = oContainerRdos.filter(':checked');
    var checkedCollections = oCollectionRdos.filter(':checked');

    // The first waste type and discount are selected by default on page load, so make sure its event is fired
    if (!aOrderedProducts.length || hasPreselect) {
        oWasteTypeRdos.filter(':checked').trigger('change');
        oDiscountRdos.filter(':checked').trigger('change');
    }

    initCartTooltipLinks();

    $('.wrapper-order-summary .link-additem').on('click', startNewProduct);

     // Now that everything has been initialised, allow the boolean which altered some init features
    // to return back to its regular state
    bPopulatedFromSess = false;

    // Get the preselected containers and check them (again). If the container was preselected, it has been filtered away by default.
    if (!aOrderedProducts.length || hasPreselect) {
        checkedContainers.prop('checked', true);
        oContainerRdos.filter(':checked').trigger('change');
        checkedCollections.prop('checked', true);
    }

    oCollectionRdos.filter(':checked').trigger('change');

    if (window.location.hash.indexOf('after-waste-type') !== -1) {
        var oScrollElem = $(window.location.hash);
        var oParentElem = oScrollElem.parent();
        var oParentPrevElem = oParentElem.prev('.order-component');

        if (typeof oParentPrevElem !== 'undefined') {
            oScrollElem = oParentPrevElem;
        }

        var iScrollTo = oScrollElem.offset().top - (oParentElem.outerHeight() / 2);

        $('html, body').stop().animate({ scrollTop: iScrollTo });
    }

    oRentContainerForm.on('submit', function(e) {
        setButtonLoadingState(oSubmitBtn);
    });
}

// This function is called each time a product is added to the cart and serves to make sure
// the trash icons on all order lines are supplied with the Bootstrap tooltip feature
function initOrderLineDelete() {
    $('.wrapper-order-summary .icon-trash').each(function () {
        var oDeleteIcon = $(this);
        if (!oDeleteIcon.hasClass('bound')) {
            var htmlTooltip = '<span class="orderline-delete-title">Weet u het zeker?</span>' +
                '<div class="clearfix">' +
                '<span class="orderline-delete delete-link">Verwijder</span>' +
                '<span class="orderline-delete cancel-link">Annuleer</span>' +
                '</div>';

            oDeleteIcon.tooltip({
                html: true,
                placement: 'bottom',
                title: htmlTooltip,
                trigger: 'click'
            });

            oDeleteIcon.addClass('bound');
        }
    });
}

function initCartTooltipLinks() {
    var oWrapperOrderSummary = $('.wrapper-order-summary');

    oWrapperOrderSummary.on('click', '.delete-link', function () {
        var oOrderLine = $(this).parents('.orderline').first();
        var iPrId = oOrderLine.data('prid');

        removeProductFromCart(iPrId, false);
    });

    oWrapperOrderSummary.on('click', '.cancel-link', function () {
        var oTooltip = $(this).parents('.tooltip').first();
        oTooltip.tooltip('hide');
    });
}

function initRentContainerStep2() {
    var oRentContainerFinishForm = $('#rentContainerFinish');
    var oSubmitBtn = $('#orderComplete');
    oFinalTotalPrice = $('.totalprice');
    iOrigTotalPrice = parseInt(oFinalTotalPrice.data('price'));

    // Prevent spaces in the phone number field
    oRentContainerFinishForm.find('input[type="tel"]').on('keydown', preventSpaces);

    var iLastTabIndex = 250; // TODO get dynamicly

    oRentContainerFinishForm.on('keyup', function (e) {
        var target = e.srcElement || e.target;

        if (target.hasAttribute("maxlength") === false) {
            return;
        }

        var iMaxLength = parseInt(target.attributes["maxlength"].value, 10);
        var iMyLength = target.value.length;

        var oTabbables, iCurrIndex;

        if (iMyLength > iMaxLength) {
            //typed to fast. move exess charakters to next field
            var value = target.value;
            target.value = value.substring(0, iMaxLength);
            var extraTxt = value.substring(iMaxLength);

            oTabbables = document.querySelectorAll(".tabable");
            iCurrIndex = target.tabIndex; //get current elements tab index

            if (iCurrIndex === iLastTabIndex) { //if we are on the last tabindex, go back to the beginning
                iCurrIndex = 0;
            }

            for (var i = 0; i < oTabbables.length; i++) { //loop through each element
                if (oTabbables[i].tabIndex === (iCurrIndex + 1)) { //check the tabindex to see if it's the element we want
                    oTabbables[i].value = extraTxt + oTabbables[i].value; //if it's the one we want, add the extra txt

                    event = document.createEvent("HTMLEvents");
                    event.initEvent("keyup", true, true);
                    event.eventName = "keyup";
                    oTabbables[i].dispatchEvent(event);
                    break;
                }
            }
        }

        if (iMyLength >= iMaxLength) {
            oTabbables = document.querySelectorAll(".tabable");
            iCurrIndex = target.tabIndex; //get current elements tab index

            if (iCurrIndex === iLastTabIndex) { //if we are on the last tabindex, go back to the beginning
                iCurrIndex = 0;
            }

            for (var i = 0; i < oTabbables.length; i++) { //loop through each element
                if (oTabbables[i].tabIndex === (iCurrIndex + 1)) { //check the tabindex to see if it's the element we want
                    oTabbables[i].focus(); //if it's the one we want, focus it and exit the loop
                    break;
                }
            }
        } else if (iMyLength === 0) {
            // Move to previous field if empty (user pressed backspace)
            var oPrevious = target;

            while (oPrevious = oPrevious.previousElementSibling) {
                if (oPrevious === null) {
                    break;
                }

                if (oPrevious.tagName.toLowerCase() === "input") {
                    oPrevious.focus();
                    break;
                }
            }
        }
    });

    // Use the 'live' approach to make this VWO A/B test proof
    oRentContainerFinishForm.on('change', 'input[name="PaymentMethodId"]', step2PaymentMethodChanged);
    //oPaymentMethodRdos.on('change', step2PaymentMethodChanged);

    if ($('.addLocation').length > 0) {
        step1FieldsToggle(false);

        $('.addLocation').on('click', function () {
            if ($('input[name="DefaultAddLocation"]').val() === "false") {
                $(this).html($(this).data("selector"));
                $('input[name="DefaultAddLocation"]').val('true');
                $('select[name="CustomerLocationId"]').attr('disabled', 'disabled');

                $(this).closest('.row').next().show();

                step1FieldsToggle(true);
            } else {
                $(this).html($(this).data("add"));
                $('input[name="DefaultAddLocation"]').val('false');
                $('select[name="CustomerLocationId"]').removeAttr('disabled');

                $(this).closest('.row').next().hide();

                step1FieldsToggle(false);
            }
        });
    }

    $('#SameInvoiceAddress').on('change', function () {
        step2FieldsToggle($(this), '.wrapper-billingaddress');
    });

    $('input[name="StartDateOptions"]').on('change', function () {
        step2FieldsToggle($('#StartDate_Quick'), '.wrapper-startdate');
    });

    if ($('.address-finder').length > 0) {
        initAddressFinder();
    }

    //console.log(oRentContainerFinishForm);
    oRentContainerFinishForm.data('toggle', 'validator');

    oRentContainerFinishForm.on('click', '.saveOrder', saveOrder);

    $(".wrapper-startdate__date").datepicker(
        {
            minDate: 0,
        },
        $.datepicker.regional['nl'],
        {
            dateFormat: "dd-mm-yy"
        }
    );

    oRentContainerFinishForm.validator().on('submit', function (e) {
        if (!e.isDefaultPrevented()) {
            setButtonLoadingState(oSubmitBtn);
        }
      });

    $('.js-uppercase-input').on('keyup', function () {
        upperCaseLetters($(this), oRentContainerFinishForm);
    });
}


function openOverlay() {
    var overlay = $('.overlay-product');
    var open = $('.js-open-overlay');
    var close = $('.js-close-overlay');

    open.click(function () {
        window.scrollTo(0, 0); // Force scroll to top.

        var target = $(this).data('target');
        if (target.length) {
            $('[data-name = ' + target + ']').addClass('is-active');
        } else {
            overlay.addClass('is-active');
        }
    });

    close.click(function () {
        overlay.removeClass('is-active');
    });
}

function openMenuOverlay() {
    var overlay = $('.overlay-menu');
    var open = $('.js-open-menu-overlay');
    var close = $('.js-close-menu-overlay');
    var triggermenu = $('.trigger-menu');
    var test = $('.js-toggle-menu');
    var body = $('body');

    test.click(function () {
        if (body.hasClass('menu-opened')) {
            body.removeClass('menu-opened');
        } else {
            body.addClass('menu-opened');
        }
        overlay.toggleClass('is-active');
        triggermenu.toggleClass('is-active');
        triggermenu.toggleClass('js-open-menu-overlay');
        triggermenu.toggleClass('js-close-menu-overlay');
    });

    //open.click(function () {
    //    overlay.addClass('is-active');
    //    triggermenu.addClass('is-active');
    //    triggermenu.addClass('js-close-menu-overlay');
    //    triggermenu.removeClass('js-open-menu-overlay');

    //    close = $('.js-close-menu-overlay');
    //});

    //close.click(function () {
    //    overlay.removeClass('is-active');
    //    triggermenu.removeClass('is-active');
    //    triggermenu.addClass('js-open-menu-overlay');
    //    triggermenu.removeClass('js-close-menu-overlay');

    //});
}

function prefillLogin() {
    if (window.location.search.indexOf('?username') >= 0) {
        var username = window.location.search.split('=')[1];
        var oLoginInput = $('input[name="Username"]');
        var oPasswordInput = $('input[name="Password"]');

        oLoginInput.val(username);
        oPasswordInput.focus();
    }
}

function initWasteTypesBlock() {
    $('.block-wastetypes__item').on('click', function (e) {
        var iWasteTypeId = $(this).data('wastetypeid');
        var bAvailable = (typeof $(this).data('available') !== 'undefined') && ($(this).data('available').toLowerCase() === 'true');

        if (typeof iWasteTypeId !== 'undefined' && iWasteTypeId !== null) {
            e.preventDefault();

            window.location.href = '/umbraco/surface/rentcontainer/prefillwastetype?id=' + iWasteTypeId + '&available=' + bAvailable;

            e.stopPropagation();
        }
    });
}

function toggleEditPaymentMethodFields(e) {
    var paymentMethodId = $(e.currentTarget).val();
    var oBankAccountNumberRow = $('.bankaccountnumber-row');
    var oBankAccountNameRow = $('.bankaccountname-row');
    var oBankAccountNumberInput = $('#BankAccountNumber');
    var oBankAccountNameInput = $('#BankAccountName');

    if (paymentMethodId == 2) {
        oBankAccountNumberRow.removeClass('hidden');
        oBankAccountNameRow.removeClass('hidden');
        oBankAccountNumberInput.prop('required', true);
        oBankAccountNameInput.prop('required', true);
    } else {
        oBankAccountNumberRow.addClass('hidden');
        oBankAccountNameRow.addClass('hidden');
        oBankAccountNumberInput.prop('required', false);
        oBankAccountNameInput.prop('required', false);
    }
}

function tabList() {
    var trigger = $('a[data-toggle="tab-list"]');

    trigger.click(function (e) {
        e.preventDefault();

        var parentData = $(this).attr("data-parent");
        var parent = document.getElementById(parentData);

        var currentTab = $(parent).find(".is-active");
        var currentTrigger = $("a[data-parent=" + parentData + "].is-active");

        var targetData = $(this).attr("data-target");
        var target = document.getElementById(targetData);

        $(currentTrigger).removeClass("is-active");

        $(currentTab).removeClass("is-active");
        $(currentTab).one(getCss3EndEventName('Transition'), function() {
            $(currentTab).addClass('is-inactive');
        });

        $(target).removeClass('is-inactive');
        var fBecomeActive = function () {
            $(target).addClass("is-active");
            clearTimeout(fBecomeActive);
        };
        setTimeout(fBecomeActive, 0);

        $(this).addClass("is-active");
    });
}
// This function searches the JSON object of products and returns the one that matches all of the supplied components
// @oComponents - an object consisting of the three option IDs which make up a unique product
function getProduct(oComponents) {
    var oProduct = null;

    $.each(jsonProducts, function (i, product) {
        if (
            product.WasteTypeId == oComponents.WasteTypeId &&
            product.ContainerId == oComponents.ContainerId &&
            product.CollectionId == oComponents.CollectionId
        ) {
            oProduct = product;
            return false;
        }
    });

    return oProduct;
}


// This function searches the JSON object of products and returns the one that matches the passed product ID
// @iPrId - an integer referring to the ID of the product to look for
function getProductById(iPrId) {
    var oProduct = null;

    $.each(jsonProducts, function (i, product) {
        if (product.Id == iPrId) {
            oProduct = product;
            return false;
        }
    });

    return oProduct;
}


// This function controls whether or not a certain step should be (in)active based on changes made on a different step
// @sStepClass - the CSS class of the step to toggle
// @bActive - whether or not the step should be active
function stepActiveToggle(sStepClass, bActive) {
    var oStep = $(sStepClass);
    var oSubSteps = oStep.find('.order-step-sub');
    var sRdoSelector = '> .row div > .wrapper-order-components > .order-component input[type="radio"]';
    var oFirstSub;

    if (bActive) {
        oStep.removeClass('inactive hidden faded');

        if (oSubSteps.length === 0) {
            oStep.find(sRdoSelector).removeAttr('disabled');
        } else {
            oFirstSub = oSubSteps.first();

            oFirstSub.removeClass('inactive');
            oFirstSub.find(sRdoSelector).removeAttr('disabled');
        }
    } else {
        oStep.addClass('inactive');

        if (oSubSteps.length === 0) {
            oStep.find(sRdoSelector).attr('disabled', 'disabled');
        } else {
            oFirstSub = oSubSteps.first();

            oFirstSub.addClass('inactive');
            oFirstSub.find(sRdoSelector).attr('disabled', 'disabled');
        }
    }
}


// This function controls whether or not a certain step should be (in)visible based on global variables
// @sStepClass - the CSS class of the step to toggle
// @bDisplay - whether or not the step should be visible
function stepDisplayToggle(sStepClass, bDisplay) {
    var oStep = $(sStepClass);

    if (bDisplay) {
        oStep.removeClass('hidden');

        var fFadeIn = function () {
            oStep.removeClass('faded');
            clearTimeout(fFadeIn);
        };
        setTimeout(fFadeIn, 0);
    } else {
        oStep.addClass('faded hidden');
    }
}

// This function checks which product is active and either adds or modifies it in the 'cart'
// @oPrd - the JSON instance representing the selected product
function addProductToCart(oPrd) {
    var oWrapperProducts = $('.products-orderlines');
    var oAddNewProduct = $('.add-order-item');
    var oTotalPrice = $('.complete-price');
    var oSubmitBtn = $('#orderComplete');

    var bAnimateAfterAdd = true;

    var htmlCurrProduct = htmlPrdCart;
    //var index = $('.orderline').length ? parseInt($('.orderline:last-child').data("index"), 10) + 1 : 1;

    //i++;
    // = htmlCurrProduct.replace('{index}', index);
    htmlCurrProduct = htmlCurrProduct.replace('{id}', oPrd.Id/* + "-" + i*/);
    htmlCurrProduct = htmlCurrProduct.replace(/{wastetype}/g, oPrd.WasteType).replace('{wastetype_img}', ((typeof oPrd.WasteTypeImg !== 'undefined') ? sWasteTypeImgDir + oPrd.WasteTypeImg + '?mode=pad&width=60&&height=50' : 'http://placehold.it/55x60?text=' + oPrd.WasteType));
    htmlCurrProduct = htmlCurrProduct.replace(/{container}/g, oPrd.Container).replace('{container_img}', ((typeof oPrd.ContainerImg !== 'undefined') ? sContainerImgDir + oPrd.ContainerImg + '?mode=pad&width=60&&height=50' : 'http://placehold.it/55x60?text=' + oPrd.Container));
    htmlCurrProduct = htmlCurrProduct.replace(/{collection}/g, oPrd.Collection).replace('{collection_img}', '/images/truck-60x60-transparent.png?mode=pad&width=60&&height=50');

    if (iActiveCartPrd > 0) {
        removeProductFromCart(iActiveCartPrd, true);
        bAnimateAfterAdd = false;   // The user thinks the product is being edited, not added again
    }

    oSubmitBtn.before($('<input type="hidden" name="ProductId" class="prid-' + oPrd.Id/* + "-" + i */ + '" value="' + oPrd.Id + '" />'));

    if (!bPopulatedFromSess) {
        aOrderedProducts.push(oPrd);
    }

    updateTotalPrice();

    if (!bPopulatedFromSess) {
        iActiveCartPrd = oPrd.Id/* + "-" + i*/;
    }

    oWrapperProducts.append(htmlCurrProduct);

    // Bring subtle attention to the product which was just added
    var oCurrCartProduct = oWrapperProducts.find('.orderline[data-prid="' + oPrd.Id + /*"-" + i + */'"]');
    if (bAnimateAfterAdd && !bPopulatedFromSess) {
        var fDelayHighlight = function () {
            oCurrCartProduct.addClass('highlight');
            oCurrCartProduct.one(getCss3EndEventName('Transition'), function () {
                oCurrCartProduct.removeClass('highlight');
            });

            clearTimeout(fDelayHighlight);
        };
        setTimeout(fDelayHighlight, 0);
    }

    initOrderLineDelete();

    if (oAddNewProduct.hasClass('hidden')) {
        oAddNewProduct.removeClass('hidden');
        oTotalPrice.removeClass('hidden');
        var fFadeInAddLink = function () {
            oAddNewProduct.removeClass('faded');
            oTotalPrice.removeClass('faded');
            clearTimeout(fFadeInAddLink);
        };
        setTimeout(fFadeInAddLink, 0);
    }

    if (typeof oSubmitBtn.attr('disabled') !== 'undefined') {
        oSubmitBtn.removeAttr('disabled');
        oSubmitBtn.removeClass('is-loading');
    }

    if (window.location.hash === '#Besteloverzicht') {
        var anchor = document.querySelector('[name="Besteloverzicht"]');
        anchor.scrollIntoView();
    }
}


// This function removes the product currently being edited or the one the user clicked 'delete' for from the cart
// @iPrId - integer referring to the product ID to be removed
// @iPrIndex - interger referring to the index of the product line
// @bReAdd - boolean stating whether or not this product is actually being replaced (with a new configuration)
function removeProductFromCart(iPrId, bReAdd, bPrdAlter) {
    var oPrOrderLine = $('.products-orderlines .orderline[data-prid="' + iPrId + '"]');

    if (bReAdd) {
        oPrOrderLine.addClass('instantfade');
    }

    oPrOrderLine.addClass('faded');
    var EmptyCart = function () {
        oPrOrderLine.remove();

        // If the cart is now empty, return it to its initial state
        var oEmptyCart = $('.empty-cart');
        var oOrderLines = $('.products-orderlines');
        var oAddNewProduct = $('.add-order-item');
        var oTotalPrice = $('.complete-price');
        /* Commented out for GPGSUP-90 */
        //var oDiscountBar = $('.orderline__discount');
        var oSubmitBtn = $('#orderComplete');

        if (oOrderLines.find('.orderline').length === 0) {
            // Only show the empty message if the now empty cart isn't going to be populated again right after this method
            if (!bReAdd) {
                oEmptyCart.removeClass('hidden');
                var fFadeInEmptyMsg = function () {
                    oEmptyCart.removeClass('faded');
                };
                setTimeout(fFadeInEmptyMsg, 0);

                oAddNewProduct.addClass('faded');
                oTotalPrice.addClass('faded');

                oAddNewProduct.addClass('hidden');
                oTotalPrice.addClass('hidden');
                /* Commented out for GPGSUP-90 */
                //oDiscountBar.addClass('hidden');

                oSubmitBtn.attr('disabled', 'disabled');

                // Don't initialize a new product instance if the user was just changing a
                // previous step of the current product
                if (!bPrdAlter) {
                    startNewProduct();
                }
            }

            oOrderLines.addClass('hidden');
        }

        if (bReAdd) {
            oPrOrderLine.removeClass('instantfade');
        }
    }
    setTimeout(EmptyCart, 0);

    var oProductInputField = $('.prid-' + iPrId);

    oProductInputField.each(function (i, el) {
        $(el).remove();
    });

    //$('.prid-' + iPrId).remove();

    aOrderedProducts = aOrderedProducts.filter(function (oPrd) {
        return oPrd.Id !== iPrId;
    });

    // Reset the global variable so that no product in the cart is active
    iActiveCartPrd = 0;

    if (!bReAdd) {
        // If this method was invoked by the addProductToCart method, that will take care of updating the total price
        updateTotalPrice();

        // Unless the current product just had one of its previous components altered, make sure all
        // references and visual indications to the previously active product are removed
        if (!bPrdAlter) {
            // Make sure the array of product components also has its entry for this product removed (keep it clean!)
            removeComponentsForProduct(iPrId);

            resetOrderOpts();
            oWasteTypeRdos.first().trigger('change');
        }
    }
}

// This method updates the total price inside the discount Rdo´s
// what the current total price in the cart is (taking discount into account too)
function updateTotalPrice() {
    if (aOrderedProducts.length > 0) {
        var oProductsSum = aOrderedProducts.reduce(function (a, b) {
            return {
                Price: a.Price + b.Price
            };
        });

        if (oProductsSum !== null && (typeof oProductsSum.Price !== 'undefined') && !isNaN(oProductsSum.Price)) {
            var iProductsTotal = oProductsSum.Price;

            if (oDiscountRdos.length > 0) {
                oDiscountRdos.each(function (idx, oRdo) {
                    var _oRdo = $(oRdo);
                    var priceWrapper = _oRdo.parent().find('#discount-price-' + oRdo.value);
                    var iYearDiscount = (1 - (parseFloat(_oRdo.data('percentage')) / 100));
                    var iTotalWithDiscount = Math.round(iProductsTotal * iYearDiscount);

                    var oRdoCollapse = _oRdo.parent().find('.discount-collapse');
                    oRdoCollapse.html('<ul>' + aOrderedProducts.map(function (prod) {
                        return '<li><span>'+ prod.WasteType + ' ' + prod.Container + '</span>' + convertCentsToEuros(prod.Price, true) + '</li>';
                    }).join('') + '<li><span>Totaal</span>' + convertCentsToEuros(iProductsTotal, true) + '</li></ul>');

                    if (priceWrapper) {
                        // has discount
                        if (iYearDiscount < 1 ) {
                            priceWrapper.html('<del>'+ convertCentsToEuros(iProductsTotal, true) +'</del>' + convertCentsToEuros(iTotalWithDiscount, true));
                        } else {
                            priceWrapper.text(convertCentsToEuros(iTotalWithDiscount, true));
                        }
                    }
                });
            }
        }
    }
}


// This function serves to check the global object storing the current selection of IDs and
// update all of the substeps to exclude options which are no longer possible
function substepAvailableOpts(oOrderLine) {
    var aAvailableProducts = jsonProducts;

    aAvailableProducts = $.grep(aAvailableProducts, function (product) {
        var oAvailable = {
            'WasteType': false,
            'Container': false,
            'Collection': false
        };

        if (product.WasteTypeId === oOrderLine.WasteTypeId) {
            oAvailable.WasteType = true;
        }

        if (typeof oOrderLine.ContainerId !== 'undefined') {
            if (oAvailable.WasteType && product.ContainerId == oOrderLine.ContainerId) {
                oAvailable.Container = true;
            }
        } else {
            oAvailable.Container = true;
        }

        if (typeof oOrderLine.CollectionId !== 'undefined') {
            if (oAvailable.WasteType && oAvailable.Container && product.CollectionId == oOrderLine.CollectionId) {
                oAvailable.Collection = true;
            }
        } else {
            oAvailable.Collection = true;
        }

        return (oAvailable.WasteType && oAvailable.Container && oAvailable.Collection);
    });

    return aAvailableProducts;
}


function removeComponentsForProduct(iPrId) {
    var oProduct = getProductById(iPrId);

    if (oProduct !== null) {
        aOrderComponents = aOrderComponents.filter(function (oPrComps) {
            var bKeepComps = false;

            if (
                oPrComps.WasteTypeId != oProduct.WasteTypeId &&
                oPrComps.ContainerId != oProduct.ContainerId &&
                oPrComps.CollectionId != oProduct.CollectionId
            ) {
                bKeepComps = true;
            }

            return bKeepComps;
        });
    }
}


// This function serves to change the overview block from a seemingly empty cart
// to one displaying all of the products, discounts et cetera; can be called from
// event handlers but also manually when products are added from session
function activateCart() {
    var oEmptyCart = $('.empty-cart');
    var oOrderLines = $('.products-orderlines');
    var oDiscountStep = $('.step1-4');

    if (!oEmptyCart.hasClass('hidden')) {
        oEmptyCart.addClass('faded');
        oEmptyCart.one(getCss3EndEventName('Transition'), function () {
            oEmptyCart.addClass('hidden');
        });
    }

    if (oOrderLines.hasClass('hidden')) {
        oOrderLines.removeClass('hidden');
    }

    if (oDiscountStep.hasClass('inactive')) { stepActiveToggle('.step1-4', true); }
    if (aOrderedProducts.length > 0 && oDiscountStep.hasClass('faded')) { stepDisplayToggle('.step1-4', true); }

    recalcStickyKit();
}

// This function sets up the Postcode.nl API
function initAddressFinder() {
    $('.address-finder').each(function () {
        var oWrapper = $(this);

        var sZipcodeNrsClss = '.address-finder-post-code-numbers';
        var sZipcodeLttrsClss = '.address-finder-post-code-letters';

        var sZipcodeNumbers = oWrapper.find(sZipcodeNrsClss).val() || '';
        var sZipcodeLetters = oWrapper.find(sZipcodeLttrsClss).val() || '';
        var sZipcode = sZipcodeNumbers + sZipcodeLetters;

        oWrapper.addressfinder(sZipcode, '.address-finder-house-number', '.address-finder-house-number-addition', '.address-finder-street', '.address-finder-city', sZipcodeNrsClss, sZipcodeLttrsClss, '.address-finder-result-message', '.address-finder-spinner');
        //$('#rentContainerFinish').validator('update');
    });
}

function setButtonLoadingState(oBtn) {
    oBtn.attr('disabled', true);
    oBtn.addClass('is-loading');
}

function removeButtonLoadingState(oBtn) {
    oBtn.attr('disabled', false);
    oBtn.removeClass('is-loading');
}

// This function adds a 'Product View' of the selected product to Datatrics
// @oPrd - the JSON instance representing the selected product
function trackProductView(oPrd) {
    window._paq.push(['setEcommerceView',
        "P" + oPrd.Id, // SKU: Product unique identifier (required)
        oPrd.WasteType + " - " + oPrd.Container + " - " + oPrd.Collection, // Product name
        oPrd.WasteType, // Product category, array for multiple (max. 5)
        parseFloat(oPrd.Price / 100) // Product price (recommended)
    ]);

    window._paq.push(['setEcommerceView',
        false,
        false,
        oPrd.WasteType // Product category, array for multiple (max. 5)
    ]);

    window._paq.push(['trackPageView']);
}


function initPricesBlock() {
    var discountButtons = $('.js-prices-discount');
    var priceTableCells = $('.js-block-prices td[data-price]')
    var monthsFreeAlert = $('.js-prices-discount-alert')
    var preselectedDiscountId = $('.js-block-prices').data('preselected-discount')



    var toggleMonthsFreeAlert = function (discountId) {
        var monthsFree = $(`.js-prices-discount[data-discount-id="${discountId}"]`).data('months-free')

        if (monthsFree && monthsFree > 0) {
            monthsFreeAlert.removeClass('hidden')
            monthsFreeAlert.text(`Eerste ${monthsFree} maand${monthsFree > 1 ? 'en' : ''} gratis!`)
        } else {
            monthsFreeAlert.addClass('hidden')
            monthsFreeAlert.text('')
        }
    }

    if (preselectedDiscountId > 0) {
        toggleMonthsFreeAlert(preselectedDiscountId)
    }

    $(discountButtons).on('click', function() {
        var discountId = $(this).data('discount-id');
        var discountPercentage = (1 - (parseFloat($(this).data('discount-percentage')) / 100));

        $(discountButtons).removeClass('active');
        $(this).addClass('active');

        toggleMonthsFreeAlert(discountId)

        priceTableCells.each(function(idx, cell) {
            changePriceInCell(cell, discountPercentage, discountId);
        });
    });
}



function changePriceInCell(cell, discountPercentage, discountId) {
    if (!cell) return;

    var basePrice = $(cell).data('price');
    var newPrice = Math.round(basePrice * discountPercentage);
    var onClickStr = $(cell).attr('onclick');

    var searchParams = new URLSearchParams(onClickStr);
    // Set discountId to the new discount Id
    searchParams.set('discountId', discountId);

    // Replace the price of the product in the cell
    $(cell).text(convertCentsToEuros(newPrice, true));

    // Replace the OnClick attribute with the new url with changed DiscountId
    $(cell).attr('onclick', decodeURIComponent(searchParams.toString()) + "'");
}


/********** EVENT HANDLERS **********************************/

// FIX for Bootstrap tooltips that are hidden by manual JS and otherwise would have to be clicked twice again to re-open
$('body').on('hidden.bs.tooltip', function (e) {
    $(e.target).data('bs.tooltip').inState = { click: false, hover: false, focus: false };
});

// This event handler prevents the user from entering certain keys in an input field marked as currency
function restrictCurrencyInput(e) {
    var aAllowedKeys = [8, 37, 39, 46, 110, 188, 190];
    var iKeyCode;

    for (iKeyCode = 48; iKeyCode < 58; iKeyCode++) { aAllowedKeys.push(iKeyCode); }
    for (iKeyCode = 96; iKeyCode < 106; iKeyCode++) { aAllowedKeys.push(iKeyCode); }

    if ($.inArray(e.keyCode, aAllowedKeys) === -1) {
        e.preventDefault();
        return false;
    }
}

/* === ORDER PROCESS - STEP 1 ======================= */

// Whenever the user selects options on Step 1 of the order process, depending on whether or not further details
// were filled in yet, either the following sub-step should be shown or all information on the page should be updated
// to reflect new prices and new or unavailable options

function step1WasteTypeChanged() {
    var oCurrWasteType = $(this);
    var iWasteTypeId = parseInt($(this).val());
    var oCurrOrderLine;

    var bPrevContainerId = false;  // Until proven

    // If the user has yet to start adding products or has had products from session been restored,
    // add a new product components object to the array; otherwise, edit the last object in the array
    if (aOrderComponents.length === 0 || bPopulatedFromSess) {
        aOrderComponents.push({ 'WasteTypeId': iWasteTypeId });
        oCurrOrderLine = aOrderComponents[aOrderComponents.length - 1];
    } else {
        oCurrOrderLine = aOrderComponents.slice(-1)[0];
        oCurrOrderLine.WasteTypeId = iWasteTypeId;

        if (typeof oCurrOrderLine.ContainerId !== 'undefined') {
            bPrevContainerId = true;
            delete oCurrOrderLine.ContainerId;
        }

        if (typeof oCurrOrderLine.CollectionId !== 'undefined') { delete oCurrOrderLine.CollectionId; }
    }
    if (iWasteTypeId == 1) {
        $("label[for='Container_5']").parent().removeClass("hidden");
    } else {
        $("label[for='Container_5']").parent().addClass("hidden");
    }
    if (iWasteTypeId == 1) {
        $("label[for='Container_5']").parent().removeClass("hidden");
    } else {
        $("label[for='Container_5']").parent().addClass("hidden");
    }

    // Determine whether or not the discount options should be available, depending on whether or not
    // there are complete products in the designated array
    if (aOrderedProducts.length === 0) {
        stepActiveToggle('.step1-4', false);

        if (!$('.step1-4').hasClass('faded')) { stepDisplayToggle('.step1-4', false); }
    }

    var aAvailableProducts = substepAvailableOpts(oCurrOrderLine);

    // Update step 2 & step 3
    resetOrderOpts('container', bPrevContainerId);
    resetOrderOpts('collection', bPrevContainerId);
    oContainerRdos.each(toggleProductOpts(aAvailableProducts, 'container'));
    oCollectionRdos.each(toggleProductOpts(aAvailableProducts, 'collection'));

    if (!$('.step-composition').hasClass('inactive')) {
        stepActiveToggle('.step-composition', false);
        //stepActiveToggle('.step1-4', false);

        $('.wrapper-discount-amount').addClass('hidden');
    }
}


function step1ContainerChanged() {
    var oCurrContainer = $(this);
    var iContainerId = parseInt($(this).val());
    var oCurrOrderLine = aOrderComponents.slice(-1)[0];

    oCurrOrderLine.ContainerId = iContainerId;
    var bPrevCollectionId = false;  // Until proven

    if (typeof oCurrOrderLine.CollectionId !== 'undefined') {
        bPrevCollectionId = true;
        delete oCurrOrderLine.CollectionId;
    }

    var aAvailableProducts = substepAvailableOpts(oCurrOrderLine);

    // Update step 3
    resetOrderOpts('collection', bPrevCollectionId);
    oCollectionRdos.each(toggleProductOpts(aAvailableProducts, 'collection'));

    if ($('.step-composition').hasClass('inactive')) {
        stepActiveToggle('.step-composition', true);
    } else if (!$('.step1-4').hasClass('inactive')) {
        // ...
    }
}


function step1CollectionChanged() {
    var oCurrCollection = $(this);
    var iCollectionId = parseInt($(this).val());
    var oCurrOrderLine = aOrderComponents.slice(-1)[0];

    oCurrOrderLine.CollectionId = iCollectionId;

    // Now that all options have been selected, find the unique corresponding product
    var oProduct = getProduct(oCurrOrderLine);

    addProductToCart(oProduct);
    trackProductView(oProduct);

    activateCart();

    $('html, body').stop().animate({
        scrollTop: $(".order-summary").offset().top
    }, 1200);
}


function step1DiscountChanged() {
    oCurrDiscount = $(this);

    // Reverse the discount percentage so that it can be used to multiply against the total price
    // E.g. 0.05 (5%) discount --> 1 - 0.05 = 0.95 --> 95% of the total cost with a 5% discount
    iYearDiscount = (1 - (parseFloat(oCurrDiscount.data('percentage')) / 100));

    var oDiscountWrapper = $('.wrapper-order-summary .discount');

    oDiscountWrapper.find('.discount-name').text(oCurrDiscount.data('title'));

    if (aOrderedProducts.length > 0 && oDiscountWrapper.hasClass('hidden')) {
        oDiscountWrapper.removeClass('hidden');
        var fFadeIn = function () {
            oDiscountWrapper.removeClass('faded');
            clearTimeout(fFadeIn);
        };
        setTimeout(fFadeIn, 0);
    }

    updateTotalPrice();
}


function startNewProduct() {
    iActiveCartPrd = 0;

    aOrderComponents.push({ 'WasteTypeId': parseInt(oWasteTypeRdos.first().val()) });

    oWasteTypeRdos.prop('checked', false);
    oContainerRdos.prop('checked', false);
    oCollectionRdos.prop('checked', false);

    var oDefWasteType = oWasteTypeRdos.first();
    oDefWasteType.prop('checked', true);
    oDefWasteType.trigger('change');

    // Physically take the user back to step 1
    $('html, body').stop().animate({ scrollTop: $('#rentContainer').offset().top });
}

/* ================================================== */

/* === ORDER PROCESS - STEP 2 ======================= */

function step2PaymentMethodChanged() {
    // In case VWO A/B test was active, make sure to re-set some variables after they were replaced in the DOM
    oPaymentMethodRdos = $('input[name="PaymentMethodId"]');
    oFinalTotalPrice = $('.totalprice');
    var oRentContainerFinishForm = $('#rentContainerFinish');
    oRentContainerFinishForm.validator();

    var oPaymentMethod = $(this);

    var iSelPaymentSurtax = parseInt(oPaymentMethod.data('surtax'));
    var iFinalTotalPrice = iOrigTotalPrice + iSelPaymentSurtax;

    var BankAccountNumber = $("#BankAccountNumber");
    var BankAccountName = $("BankAccountName");
    var BankCity = $("BankCity");

    // Display the correct addition of surtax next to the chosen payment method option
    $('.wrapper-paymentmethod-opts .method-surtax').addClass('hidden');
    var oMethodSurtax = oPaymentMethod.parent().find('.method-surtax');
    if (oMethodSurtax.length > 0) {
        oMethodSurtax.removeClass('hidden');
    }

    oFinalTotalPrice.text(convertCentsToEuros(iFinalTotalPrice, true));

    if (oPaymentMethod.data('extrafields').toLowerCase() === 'true') {
        oPaymentMethod.parent().next('.paymentmethod-extrafields').slideDown();

        BankAccountNumber.attr('pattern', '^(?=[0-9A-Z]{18}$)NL\\d{2}[A-Z]{4,5}\\d{10}$');

        // Enable Validation
        BankAccountNumber.attr('data-validate', true);
        BankAccountName.attr('data-validate', true);
        BankCity.attr('data-validate', true);
        $('#rentContainerFinish').validator('update'); // Reinitialize validator to include hidden fields.
    } else {
        var oPaymentMethodExtraFlds = $('.paymentmethod-extrafields');
        if (oPaymentMethodExtraFlds.length > 0) {
            oPaymentMethodExtraFlds.slideUp();
            // Disable Validation
            BankAccountNumber.attr('data-validate', false);
            BankAccountName.attr('data-validate', false);
            BankCity.attr('data-validate', false);
            $('#rentContainerFinish').validator('update'); // Reinitialize validator to include hidden fields.
        }
    }

    oRentContainerFinishForm.on('click', '.saveOrder', saveOrder);
}


function step2FieldsToggle(oToggler, sElemClass) {
    var oElem = $(sElemClass);

    // Toggled forms
    var ZipcodeNrs_2 = $("#ZipcodeNrs_2");
    var ZipcodeLttrs_2 = $("#ZipcodeLttrs_2");
    var HouseNumber_2 = $("#HouseNumber_2");
    var Street_2 = $("#Street_2");
    var City_2 = $("#City_2");
    var StartDate = $("#StartDate");

    if (oToggler.prop('checked')) {
        if (oToggler.prop('id') == 'StartDate_Quick') { // Date field toggled
            StartDate.attr('data-validate', false);
        } else { // Other address field toggled
            ZipcodeNrs_2.attr('data-validate', false);
            ZipcodeLttrs_2.attr('data-validate', false);
            HouseNumber_2.attr('data-validate', false);
            Street_2.attr('data-validate', false);
            City_2.attr('data-validate', false);
        }
        $('#rentContainerFinish').validator('update'); // Reinitialize validator to include hidden fields.
        oElem.slideUp();
    } else {
        if (oToggler.prop('id') == 'StartDate_Quick') { /// Date field toggled
            StartDate.attr('data-validate', true);
        } else { // Other address field toggled
            ZipcodeNrs_2.attr('data-validate', true);
            ZipcodeLttrs_2.attr('data-validate', true);
            HouseNumber_2.attr('data-validate', true);
            Street_2.attr('data-validate', true);
            City_2.attr('data-validate', true);
        }
        $('#rentContainerFinish').validator('update'); // Reinitialize validator to include hidden fields.

        oElem.slideDown();
    }
}

function step1FieldsToggle(validate) {
   // Toggled forms
    var ZipcodeNrs_2 = $("#ZipcodeNrs_1");
    var ZipcodeLttrs_2 = $("#ZipcodeLttrs_1");
    var HouseNumber_2 = $("#HouseNumber_1_1");
    var Street_2 = $("#Street_1");
    var City_2 = $("#City_1");

    if (validate == false) {
        ZipcodeNrs_2.attr('data-validate', false);
        ZipcodeLttrs_2.attr('data-validate', false);
        HouseNumber_2.attr('data-validate', false);
        Street_2.attr('data-validate', false);
        City_2.attr('data-validate', false);
        $('#rentContainerFinish').validator('update'); // Reinitialize validator to include hidden fields.
    } else {
        ZipcodeNrs_2.attr('data-validate', true);
        ZipcodeLttrs_2.attr('data-validate', true);
        HouseNumber_2.attr('data-validate', true);
        Street_2.attr('data-validate', true);
        City_2.attr('data-validate', true);
        $('#rentContainerFinish').validator('update'); // Reinitialize validator to include hidden fields.
    }
}

/* ================================================== */

/********** AJAX CALLS **************************************/


function saveOrder(e) {

    e.preventDefault();

    var returnUrl = $(e.currentTarget).attr('href');
    var form = $('#rentContainerFinish');
    var formdata = form.serialize();

    $.ajax({
        url: '/umbraco/surface/rentcontainerfinish/ReturnPost',
        data: formdata
    }).done(function () {
        window.location.href = returnUrl;
    }).fail(function (response) {
        console.log(response);
    });
}

/********** HELPERS *****************************************/

function getOrderComponents() {
    if (aOrderedProducts.length > 0) {
        var aOrderComps = [];

        for (var iPrd = 0; iPrd < aOrderedProducts.length; iPrd++) {
            var oPrd = aOrderedProducts[iPrd];

            aOrderComps.push({
                'WasteTypeId': oPrd.WasteTypeId,
                'ContainerId': oPrd.ContainerId,
                'CollectionId': oPrd.CollectionId
            });
        }

        return aOrderComps;
    }

    return [];
}

function getOrderedProducts() {
    if (typeof currentOrder !== 'undefined') {
        bPopulatedFromSess = true;
        iYearDiscount = currentOrder.YearDiscountId;

        var aProducts = [];

        for (var iPrd = 0; iPrd < (currentOrder.Products).length; iPrd++) {
            var oProduct = getProductById(currentOrder.Products[iPrd]);
            if (oProduct !== null) {
                aProducts.push(oProduct);
            }
        }

        return aProducts;
    }

    return [];
}

// This function is invoked by a $.each() method and is supplied an extra descriptive argument
// Its purpose is to show/hide any product options which are no longer available for the current set of selected options
function toggleProductOpts(aProducts, sOptType) {
    return function () {
        var oOpt = $(this);
        var iOptId = parseInt($(this).val());

        var aAvailableOpts = $.grep(aProducts, function (product) {
            var bMatch = false;

            switch (sOptType) {
                case 'container':
                    bMatch = (product.ContainerId === iOptId);
                    break;

                case 'collection':
                    bMatch = (product.CollectionId === iOptId);
                    break;
            }

            return bMatch;
        });

        if (aAvailableOpts.length > 0) {
            oOpt.parent().removeClass('hidden');
        } else {
            oOpt.parent().addClass('hidden');
        }
    };
}

// This helper method serves to uncheck any checked properties for types specified
function resetOrderOpts(sResetType, bPrdAlter) {
    if (typeof bPrdAlter === 'undefined') {
        bPrdAlter = false;
    }

    switch (sResetType) {
        case 'container':
            oContainerRdos.prop('checked', false);
            oCollectionRdos.prop('checked', false);
            break;

        case 'collection':
            oCollectionRdos.prop('checked', false);
            break;

        default:    // All
            oWasteTypeRdos.prop('checked', false);
            oContainerRdos.prop('checked', false);
            oCollectionRdos.prop('checked', false);
            break;
    }

    if (iActiveCartPrd > 0) {
        removeProductFromCart(iActiveCartPrd, false, bPrdAlter);
    }
}

// This function returns a browser-specific event name for when transitions or animations finish
// @sType - string referring to the event type which should be retrieved (must start with a capital letter)
function getCss3EndEventName(sType) {
    var sTypeLC = sType.toLowerCase();
    var iType, oElem = document.createElement('div');

    var aEventNames = {};
    aEventNames['Webkit' + sType] = 'webkit' + sType + 'End';   // WebKit
    aEventNames['Moz' + sType] = sTypeLC + 'end';               // Mozilla
    aEventNames['O' + sType] = 'o' + sTypeLC + 'end';           // Old Opera
    aEventNames['ms' + sType] = sTypeLC + 'end';           // Internet Explorer
    aEventNames[sTypeLC] = sTypeLC + 'end';                     // Modern

    for (iType in aEventNames) {
        if (aEventNames.hasOwnProperty(iType) && (typeof oElem.style[iType] !== 'undefined')) {
            return aEventNames[iType];
        }
    }
}

// A helper function which takes an integer representing cents and returns it
// as a formatted string for euro's
function convertCentsToEuros(iCents, bIncludeEuro) {
    var euro = '';
    if (bIncludeEuro) { euro = '\u20AC'; }

    return euro + " " + ((iCents / 100).toFixed(2)).replace('.', ',');
}

// Can I Help you popup in Master.
var helpTimeout;
function helpPopupTimeout(closedCookie) {
    if (closedCookie !== 'true') { // If no cookie was found, then set timeout.
        helpTimeout = setTimeout(function () {
            helpPopup.addClass("help-popup--active");
        }, helpPopup.data('timeoutlength')); // 30 Seconds default.
    }
}
function closeHelpPopup() {
    clearTimeout(helpTimeout);
    helpPopup.removeClass("help-popup--active");
    // On close: store in Cookie and check if exists
    Cookies.set('popupClosed', 'true', { expires: 7 });
}

function showNotification(closedNotificationCookie) {
    if (closedNotificationCookie !== 'true') {
        oNotificationBar.addClass('active');
        $('body').addClass("notification-active");
    } else {
        oNotificationBar.remove();
        $('body').removeClass("notification-active");
    }
}

function closeNotification() {
    oNotificationBar.remove();
    $('body').removeClass("notification-active");
    Cookies.set('notificationClosed', 'true', { expires: 7 });
}

function validatePostNumbers(el) {
    // event.keyCode is deprecaded, only using it for IE11
    // Not more than 4, and check on numberic value

    var reNumpadDigits = /^Numpad[0-9]{1}$/;

    if (!el.value.length < 4 &&
        !(event.code && (event.code.startsWith('Digit') || reNumpadDigits.test(event.code) || event.code === 'Delete' || event.code === 'Backspace') ||
        (event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) || event.keyCode === 8 || event.keyCode === 46)) {
        event.preventDefault();
    };
}

function removeSpaces(el) {
    var val = el.value.replace(/\s/g, "");
    $(el).val(val);
    $(el).trigger("change");
}

function preventSpaces(e) {
    if (e.keyCode === 32) {
        e.preventDefault();
        return false;
    }
}

function callMeBack(url) {
    var telNr = { RequestTelNo: $('input[name=telCallMeBack]').val() };
    var helpPopupFinish = $(".help-popup__finish");
    var helpPopupContent = $(".help-popup__content");
    var helpPopupError = $(".help-popup__error");
    event.preventDefault();

    $.post(url, telNr)
        .done(function (result) {
            //   alert('Uw verzoek is verstuurd, u wordt zo spoedig mogelijk teruggebeld!');
            helpPopupContent.fadeOut();
            helpPopupFinish.fadeIn();
        })
        .fail(function () {
            //   alert('Er is een fout opgetreden, uw verzoek kan niet worden verzonden. Onze excuses hiervoor! U kunt eventueel contact opnemen via Livechat, Whatsapp (06-10712478) of telefoon (088 - 472 15 99).');
            helpPopupContent.fadeOut();

            helpPopupError.fadeIn();
        })
        .always(function () {
        });
}


function openPreviousInvoicesIframe(el) {
    var iframe = $(".previous-invoices-iframe");
    iframe.slideToggle(300);
}

function upperCaseLetters(oElem, oForm) {
    oForm.validator('update');

    oElem.val(function () {
        return this.value.toUpperCase();
    });
}

function getSiteName() {
    var site = document.body.dataset['site'];

    switch (site) {
        case 'BEDRIJFSAFVAL':
            return 'Bedrijfsafval'
        case 'MKBAFVAL':
        default:
            return 'MKB-afval';
    }
}

// This helper method triggers the recalculate method for the StickyKit plug-in to make
// sure it displays correctly after DOM changes
function recalcStickyKit() {
    $(document.body).trigger('sticky_kit:recalc');
}

function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function validatePhoneNumber(phoneNumber) {
    var re = /^(?:0|(?:\+|00) ?31 ?)(?:(?:[1-9] ?(?:[0-9] ?){8})|(?:6 ?-? ?[1-9] ?(?:[0-9] ?){7})|(?:[1,2,3,4,5,7,8,9]\d ?-? ?[1-9] ?(?:[0-9] ?){6})|(?:[1,2,3,4,5,7,8,9]\d{2} ?-? ?[1-9] ?(?:[0-9] ?){5}))$/;
    return re.test(String(phoneNumber).toLowerCase());
}

// .startsWith() IE11 Polyfill
if (!String.prototype.startsWith) {
    Object.defineProperty(String.prototype, 'startsWith', {
        value: function(search, rawPos) {
            var pos = rawPos > 0 ? rawPos|0 : 0;
            return this.substring(pos, pos + search.length) === search;
        }
    });
}

function formatCurrency(number) {
    if (!number) return;

    return number.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' })
}

function round(num, decimalPlaces) {
    if(!decimalPlaces) {
        decimalPlaces = 0
    }

    var p = Math.pow(10, decimalPlaces);
    var n = (num * p) * (1 + Number.EPSILON);
    return Math.round(n) / p;
}