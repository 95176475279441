/*****************************************************
ADDRESS FINDER
******************************************************/
(function ($) {
    $.fn.addressfinder = function (postcode, houseNumberSelector, houseNumberAdditionSelector, street, city, postCodeNumbersSelector, postCodeLettersSelector, errMessageSelector, ajaxLoadSelector) {
        var element = $(this);
        var _endpoint = '/handlers/addressfinder.ashx';

        $('.loading', element).hide();

        function showErrorMessage(message) {
            var msg = $(errMessageSelector, element);
            if (!msg || msg.length <= 0) {
                msg = $('<div/>').appendTo(element).addClass(errMessageSelector).hide();
            }

            msg.html(message);

            if ($(errMessageSelector).is(':visible') == false) {
                $(errMessageSelector).fadeTo(300, 1).delay(3000).fadeOut(800);
            }
        }

        function refreshAddress() {
            var _postcode = postcode || '';

            if (_postcode === '') {
                if (postCodeNumbersSelector) {
                    var postCodeNumbers = $(postCodeNumbersSelector, element).val() || '';
                    if (postCodeNumbers !== '') {
                        _postcode += postCodeNumbers.replace(' ', '');
                    }
                }

                if (postCodeLettersSelector) {
                    var postCodeLetters = $(postCodeLettersSelector, element).val() || '';
                    if (postCodeLetters !== '') {
                        _postcode += postCodeLetters;
                    }
                }
            }

            var houseNumber = $(houseNumberSelector, element).val().match(/([0-9])+/gm) || '';
            var houseNumberAddition = $(houseNumberAdditionSelector, element).val();

            if (houseNumber.length <= 0) { return; }

            // show loader
            $(ajaxLoadSelector, element).show();

            // call http handler
            var url = _endpoint + '?postcode=' + _postcode + '&housenumber=' + houseNumber + '&houseaddition=' + houseNumberAddition;

            $.getJSON(url, function (data) {
                $(ajaxLoadSelector, element).hide();

                if (data) {
                    if (data.Success) {
                        var address = eval('(' + data.Data + ')');
                        var returnedHouseNumberAddition = (address.houseNumberAddition || '');

                        // Ticket #19550 - if a house number addition was specified but the API didn't recognise it, give the
                        // user the benefit of the doubt and leave it tacked on to the house number instead of removing it
                        if (houseNumberAddition !== '' && returnedHouseNumberAddition === '') {
                            returnedHouseNumberAddition = houseNumberAddition;
                        }

                        $(houseNumberSelector, element).val(houseNumber);
                        $(houseNumberAdditionSelector, element).val(returnedHouseNumberAddition);
                        $(street, element).val(address.street);
                        $(city, element).val(address.city);

                        var oRentContainerFinishForm = $('#rentContainerFinish');
                        if (oRentContainerFinishForm.length > 0) {
                            oRentContainerFinishForm.validator('update');
                        }
                        showErrorMessage('');
                        return;
                    } else {
                        showErrorMessage(data.Message);
                    }
                } else {
                    showErrorMessage('Invalid data');
                }

                $(street, element).val('');
                $(city, element).val('');
            });
        }

        // blur events for all controls involved in the address
        // when any of the controls change then the address 
        // is queried and updated
        $(houseNumberSelector, element).on('blur', function () { refreshAddress(); });
        $(houseNumberAdditionSelector, element).on('blur', function () { refreshAddress(); });

        if (postCodeNumbersSelector) {
            $(postCodeNumbersSelector, element).on('blur', function () {
                refreshAddress();
            });
        }

        if (postCodeLettersSelector) {
            $(postCodeLettersSelector, element).on('blur', function () {
                refreshAddress();
            });
        }
    };
})(jQuery);